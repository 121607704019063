import React from 'react';
import { ColumnModel } from '@syncfusion/ej2-react-grids';
import { ViewChangeHistory } from './Model';

export const ErrorKeys = [
    { key: 'firstName', value: 'First Name', page: '1' },
    { key: 'lastName', value: 'Last Name', page: '1' },
    { key: 'middleInitial', value: 'Middle Initial', page: '1' },
    { key: 'medicareId', value: 'MBI', page: '1' },
    { key: 'medicalId', value: 'Medical Id', page: '1' },
    { key: 'birthdate', value: 'Date Of Birth', page: '1' },
    { key: 'homeStreet1', value: 'Address 1', page: '1' },
    { key: 'homeStreet2', value: 'Address 2', page: '1' },
    { key: 'homeCity', value: 'City', page: '1' },
    { key: 'homeZipCode', value: 'Zip Code', page: '1' },
    { key: 'proposeEffectiveDate', value: 'Proposed Effective Date', page: '1' },
    { key: 'homeState', value: 'State', page: '1' },
    { key: 'homeCounty', value: 'County', page: '1' },
    { key: 'homePhone', value: 'Phone', page: '1' },
    { key: 'emailAddress', value: 'Email', page: '1' },
    { key: 'mailingStreet1', value: 'Mail Address 1', page: '1' },
    { key: 'mailingStreet2', value: 'Mail Address 2', page: '1' },
    { key: 'mailingStreet2', value: 'Mail Address2', page: '1' },
    { key: 'mailingState', value: 'Mail State', page: '1' },
    { key: 'mailingCity', value: 'Mail City', page: '1' },
    { key: 'mailingZipCode', value: 'Mail ZipCode', page: '1' },
    { key: 'contractCode', value: 'Contract Code', page: '1' },
    { key: 'pbpCode', value: 'PBP #', page: '1' },
    { key: 'hpOption', value: 'HPOption', page: '1' },
    { key: 'startDate', value: 'StartDate', page: '1' },
    { key: 'endDate', value: 'EndDate', page: '1' },
    { key: 'signature', value: 'Signed Name', page: '1' },
    { key: 'isSignedByAuthorisedRep', value: 'Signed by an authorized representative ?', page: '1' },
    { key: 'authorizedRepName', value: 'Authorized Name', page: '1' },
    { key: 'authorizedRepAddress', value: 'Authorized Address', page: '1' },
    { key: 'authorizedRepPhone', value: 'Authorized Phone Number', page: '1' },
    { key: 'authorizedRepRelationshipToEnrollee', value: 'Relationship to Enrollee', page: '1' },
    { key: 'age', value: 'age', page: '1' },
    {
        key: 'isMemberVerifiedWithCMS',
        value: 'Please perform CMS eligibility verification by clicking the Verify button',
        page: '1',
    },
    {
        key: 'isDuplicateCheckVerified',
        value: 'Please run duplicate check by clicking the Duplicate Check button',
        page: '1',
    },
    {
        key: 'isMemberVerifiedWithMedicaid',
        value: 'State Medicaid details are not verified, You cannot proceed with the enrollment for DSNP plans.',
        page: '1',
    },
    { key: 'applicationReceiptDate', value: 'App Receipt Date', page: '2' },
    { key: 'applicationSignatureDate', value: 'App Signature Date', page: '2' },
    { key: 'applicationDate', value: 'Application Date', page: '2' },
    { key: 'name', value: 'Contact Name', page: '2' },
    { key: 'email', value: 'Email', page: '2' },
    { key: 'pcpName', value: 'Primary care provider', page: '2' },
    { key: 'medicalGroup', value: 'Medical Group', page: '2' },
    { key: 'letterReasonCode', value: 'Reason Code', page: '2' },
    { key: 'letterReason', value: 'Other Reason', page: '2' },
    { key: 'previousIpaName', value: 'Previous IPA Name', page: '2' },
    { key: 'previousPcpName', value: 'Previous PCP Name', page: '2' },
    { key: 'status', value: 'Status', page: '3' },
    { key: 'soaReceived', value: 'Soa Received', page: '3' },
    { key: 'ltFacilityName', value: 'Facility Name', page: '3' },
    { key: 'comPref', value: 'Communication Preference', page: '3' },
    { key: 'ethnicityDetails', value: 'Ethnicity Details', page: '3' },
    { key: 'raceDetails', value: 'Race Details', page: '3' },
    { key: 'mainLanguage', value: 'Main Language', page: '3' },

    { key: 'newTrans', value: 'New Or Transfer', page: '4' },
    { key: 'srep', value: 'Sales Rep', page: '4' },
    { key: 'salesType', value: 'Sales Type', page: '4' },
    { key: 'witholdOption', value: 'Withhold Option', page: '4' },
    { key: 'pbpSalesRep', value: 'PBP Sales Rep', page: '4' },

    { key: 'poaFirstName', value: 'Poa FirstName', page: '5' },
    { key: 'poaLastName', value: 'Poa LastName', page: '5' },
    { key: 'poaAddress1', value: 'Poa Address 1', page: '5' },
    { key: 'sendStatus', value: 'Send Status', page: '5' },
    { key: 'mcEffDtA', value: 'Part A Eff Date', page: '5' },
    { key: 'mcEffDtB', value: 'Part B Eff Date', page: '5' },
    { key: 'mcEffDtD', value: 'Part D Eff Date', page: '5' },
    { key: 'electionType', value: 'Election Type', page: '5' },
    { key: 'sepReasonCode', value: 'SEP Reason Code', page: '5' },
    { key: 'primaryPOA', value: 'Please select at least one Primary POA', page: '5' },

    { key: 'additionalQuestion3', value: 'additionalQuestion3', page: '5' },
    { key: 'additionalQuestion5', value: 'additionalQuestion5', page: '5' },
    { key: 'additionalQuestion6', value: 'additionalQuestion6', page: '5' },
    { key: 'additionalQuestion7', value: 'additionalQuestion7', page: '5' },
    { key: 'additionalQuestion8', value: 'additionalQuestion8', page: '5' },
    { key: 'additionalQuestion10', value: 'additionalQuestion10', page: '5' },
    { key: 'additionalQuestion11', value: 'additionalQuestion11', page: '5' },
    { key: 'additionalQuestion12', value: 'additionalQuestion12', page: '5' },
    { key: 'additionalQuestion13', value: 'additionalQuestion13', page: '5' },
    { key: 'additionalQuestion16', value: 'additionalQuestion16', page: '5' },
    { key: 'additionalQuestion17', value: 'additionalQuestion17', page: '5' },
    { key: 'conditions', value: 'Chronical conditions', page: '6' },
];

export const verifyMemberGridColumns = [
    {
        field: 'memberID',
        headerText: 'Member ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'medicareID',
        headerText: 'MBI',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'firstName',
        headerText: 'First Name',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'lastName',
        headerText: 'Last Name',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'dob',
        headerText: 'Date Of Birth',
        format: 'MM/dd/yyyy',
        type: 'date',
        autoFit: true,
    },
    {
        field: 'contractCode',
        headerText: 'Contract Code',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'pbp',
        headerText: 'PBP',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'effectiveDate',
        headerText: 'Effective Date',
        format: 'MM/dd/yyyy',
        type: 'date',
        autoFit: true,
    },
    {
        field: 'termDate',
        headerText: 'Term Date',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'levelOfConfidence',
        headerText: 'Confidence Level',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'CurrentEnrollmentStatus',
        headerText: 'Status',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'sRep',
        headerText: 'Sales Agent',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'appSigDt',
        headerText: 'App Signature Date',
        format: 'MM/dd/yyyy',
        type: 'date',
        autoFit: true,
    },
    {
        field: 'sequence',
        headerText: 'Sequence',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'tranCd',
        headerText: 'Send Status',
        type: 'text',
        autoFit: true,
    },
];

export const WorklistDetailsGridColumns: ColumnModel[] = [
    {
        field: 'avaWorkItemId',
        headerText: 'Work Item Id',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
    },
    {
        field: 'receivedVia',
        headerText: 'Source',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'fileName',
        headerText: 'File Name',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'memberId',
        headerText: 'Member ID',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'firstName',
        headerText: 'First Name',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'lastName',
        headerText: 'Last Name',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
    },
    {
        field: 'birthdate',
        headerText: 'DOB',
        type: 'date',
        format: 'MM/dd/y',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'ageInDays',
        headerText: 'Age In Days',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
    },
    {
        field: 'processingStatus',
        headerText: 'Processing Status',
        width: '180',
        clipMode: 'EllipsisWithTooltip',
        type: 'string',
        allowSorting: false,
    },
    {
        field: 'medicareId',
        headerText: 'Medicare Number / HIC',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'medicalId',
        headerText: 'Medical ID',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'proposedEffectiveDate',
        headerText: 'Effective Date ',
        type: 'date',
        format: 'MM/dd/y',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: true,
    },
    {
        field: 'contractCode',
        headerText: 'Contract',
        width: '150',
        type: 'text',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'pbpCode',
        headerText: 'Plan ',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'salesRepName',
        headerText: 'Sales Rep',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'assignedTo',
        headerText: 'Assigned Agent',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'receivedVia',
        headerText: 'Sender',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'createdByName',
        headerText: 'Created By',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'createdAt',
        headerText: 'Created Date ',
        type: 'date',
        format: 'MM/dd/y h:mm:ss a',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
    {
        field: 'onlineEnrollReferenceNo',
        headerText: 'Confirmation Number ',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
        allowSorting: false,
    },
];

export const SalesRepGridColumns = [
    {
        field: 'code',
        width: 100,
        headerText: 'Code',
        type: 'text',
    },
    {
        field: 'description',
        width: 300,
        headerText: 'Full Name',
        type: 'text',
    },
    {
        field: 'npn',
        width: 200,
        headerText: 'NPN',
        type: 'text',
    },
    {
        field: 'active',
        width: 100,
        headerText: 'Active',
        type: 'text',
    },
];

export const SalesTypeGridColumns = [
    {
        field: 'code',
        width: 200,
        headerText: 'Code',
        type: 'text',
    },
    {
        field: 'description',
        width: 200,
        headerText: 'Description',
        type: 'text',
    },
    {
        field: 'active',
        width: 100,
        headerText: 'Active',
        type: 'text',
    },
];

export const voiceRecordingsGridColumns = [
    {
        field: 'recordingURL',
        headerText: 'Recording',
        type: 'text',
        autoFit: true,
        template: () => {
            return (
                <i
                    className="fa fa-play"
                    title="Play"
                    style={{
                        fontSize: '21px',
                        marginLeft: '20px',
                    }}
                ></i>
            );
        },
    },
    {
        field: 'potentialMemberId',
        headerText: 'Member ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'userID',
        headerText: 'Agent ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'userName',
        headerText: 'Agent Name',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'callerPhoneNo',
        headerText: 'Caller ID',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'startTime',
        headerText: 'Start Time',
        format: 'MM/dd/yyyy hh:mm:ss a',
        type: 'date',
        autoFit: true,
    },
    {
        field: 'timeInSec.totalCallTime',
        headerText: 'Rec Length',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'timeInSec.holdTime',
        headerText: 'Hold Length',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'attendingPhoneNo',
        headerText: 'Attending Phone',
        type: 'text',
        autoFit: true,
    },
    {
        field: 'callId',
        headerText: 'Interaction ID',
        type: 'text',
        autoFit: true,
    },
];

export const verificationTypeList = [
    {
        text: 'Form',
        value: 'Form',
    },
    {
        text: 'Phone',
        value: 'Phone',
    },
    {
        text: 'Email',
        value: 'Email',
    },
    {
        text: 'Other',
        value: 'Other',
    },
];

export interface WorklistFilters {
    AvaWorkItemId?: string;
    memberName?: string;
    confirmationNumber?: string;
    processingStatus?: string | null;
    submittedStartDate?: Date | null;
    submittedEndDate?: Date | null;
    submittedBy?: string;
    coverageYear?: string | null;
    broker?: string | null;
    salesRep?: string | null;
    assignedEnrollmentRep?: string | null;
    userName?: string;
    isMyEnrollment?: boolean;
    statisticTileStatus?: number;
    submissionDate?: Date | null;
    MemberId?: string | null;
    source?: string | null;
}
export interface WorkQueueReAssignFilters {
    processingStatus?: string | null;
    assignedEnrollmentRep?: string | null;
    contractCode?: string | null;
    planCode?: string | null;
    newAssignedEnrollmentRep?: string | null;
    source?: string | null;
}

export interface EnrollmentIntakeIds {
    enrollmentIds?: string | null;
}

export interface EnrollmentStatisticData {
    unAssignedCount: number;
    inProgressCount: number;
    newEnrollmentCount: number;
    waitingForInfoCount: number;
    requestForInfoCount: number;
    invalidCount: number;
    completedCount: number;
    approvalRequiredCount: number;
    pbpChangesCount: number;
    snpEnrollmentsCount: number;
    aepEnrollmentsCount: number;
    allEnrollmentsCount: number;
    loading: boolean;
}

export const SourceFilterObject = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    appStatus: '',
    submissionStartDate: undefined,
    submissionEndDate: undefined,
    submittedBy: '',
    coverageYear: '',
    broker: '',
    salesRep: '',
    assignedEnrollmentRep: '',
    userName: '',
    isMyEnrollment: false,
    statisticTileStatus: 0,
    MemberId: '',
    source: 'AVA Upload Form',
};

export const ClearFilterObject = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    appStatus: '',
    submissionStartDate: undefined,
    submissionEndDate: undefined,
    submittedBy: '',
    coverageYear: '',
    broker: '',
    salesRep: '',
    assignedEnrollmentRep: '',
    userName: '',
    isMyEnrollment: false,
    statisticTileStatus: 0,
    MemberId: '',
};
export const clearFiltersDefault = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    processingStatus: null,
    submittedStartDate: null,
    submittedEndDate: null,
    coverageYear: null,
    submittedBy: '',
    salesRep: null,
    broker: null,
    submissionDate: null,
    assignedEnrollmentRep: null,
    MemberId: '',
    source: null,
};
export const clearFiltersDefaultWithSource = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    processingStatus: null,
    submittedStartDate: null,
    submittedEndDate: null,
    coverageYear: null,
    submittedBy: '',
    salesRep: null,
    broker: null,
    submissionDate: null,
    assignedEnrollmentRep: null,
    MemberId: '',
    source: 'AVA Upload Form',
};
export const clearWorkQueueFilters = {
    assignedEnrollmentRep: null,
    contractCode: null,
    planCode: null,
    source: null,
};

export const clearFiltersMyEnrollment = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    processingStatus: null,
    submittedStartDate: null,
    submittedEndDate: null,
    coverageYear: null,
    submittedBy: '',
    salesRep: null,
    broker: null,
    submissionDate: null,
    MemberId: '',
    source: null,
};

export const ClearFiltersMyEnrollmentWithSource = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    processingStatus: null,
    submittedStartDate: null,
    submittedEndDate: null,
    coverageYear: null,
    submittedBy: '',
    salesRep: null,
    broker: null,
    submissionDate: null,
    MemberId: '',
    source: 'AVA Upload Form',
};

export const ClearFilterObjectMyEnrollment = {
    AvaWorkItemId: '',
    memberName: '',
    confirmationNumber: '',
    appStatus: '',
    submissionStartDate: undefined,
    submissionEndDate: undefined,
    submittedBy: '',
    coverageYear: '',
    salesRep: '',
    broker: '',
    userName: '',
    isMyEnrollment: true,
    statisticTileStatus: 0,
    MemberId: '',
};
export const ResetStatusCount = {
    unAssignedCount: 0,
    inProgressCount: 0,
    newEnrollmentCount: 0,
    waitingForInfoCount: 0,
    requestForInfoCount: 0,
    invalidCount: 0,
    completedCount: 0,
    approvalRequiredCount: 0,
    pbpChangesCount: 0,
    snpEnrollmentsCount: 0,
    aepEnrollmentsCount: 0,
    allEnrollmentsCount: 0,
    loading: true,
};

export const AutoSearchFields = {
    text: 'enrollmentDetails',
    value: 'id',
};

export const ConfirmationList = [
    {
        text: 'Yes',
        value: 'Y',
    },
    {
        text: 'No',
        value: 'N',
    },
];

export const IsEligibleConfirmationList = [
    {
        text: 'Yes',
        value: true,
    },
    {
        text: 'No',
        value: false,
    },
];

export const Status = [
    {
        text: 'All',
        value: 0,
    },
    {
        text: 'Active',
        value: 1,
    },
    {
        text: 'Inactive',
        value: 2,
    },
];

export const ChangeHistoryColumns: (ColumnModel & { field: keyof ViewChangeHistory })[] = [
    {
        field: 'name',
        headerText: 'Field Name',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'currentValue',
        headerText: 'Updated From',
        width: 200,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'newValue',
        headerText: 'Updated To',
        width: 200,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'lastUpdatedByName',
        headerText: 'Updated By',
        clipMode: 'EllipsisWithTooltip',
        autoFit: true,
    },
    {
        field: 'lastUpdatedAt',
        headerText: 'Updated On',
        sortComparer: (a, b) => new Date(b as string).getTime() - new Date(a as string).getTime(),
    },
];

export const WebEnrollmentGridColumns: ColumnModel[] = [
    {
        headerText: 'Select All',
        type: 'checkbox',
        width: '60',
    },
    {
        field: 'Id',
        headerText: 'Enrollment Id',
        type: 'text',
        width: '120',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'firstNm',
        headerText: 'First Name',
        type: 'text',
        width: '120',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'lastNm',
        headerText: 'Last Name',
        type: 'text',
        width: '120',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'dob',
        headerText: 'DOB',
        type: 'date',
        format: 'MM/dd/y',
        width: '100',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'status',
        headerText: 'Status',
        type: 'string',
        width: '100',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'hic',
        headerText: 'Medicare Number/HIC',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'assignedMembRep',
        headerText: 'Assinged To',
        type: 'text',
        width: '150',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'updated',
        width: 150,
        headerText: 'Updated Date',
        type: 'datetime',
        autoFit: true,
        format: 'MM/dd/yyyy hh:mm:ss',
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'updatedBy',
        headerText: 'Updated By',
        type: 'text',
        width: '150',
    },
    {
        field: 'automationFailedReason',
        headerText: 'Failure Reason',
        type: 'text',
        width: '460',
        clipMode: 'EllipsisWithTooltip',
    },
];

export enum enrollmentNoteType {
    none = 0,
    autoPcpAssignment = 1,
    cmsVerificationSuccess = 2,
    cmsVerificationFailure = 3,
    medicalVerificationSuccess = 4,
    medicalVerificationFailure = 5,
    marxOverride = 6,
    medicalOverride = 7,
    duplicateFound = 8,
    newEnrollment = 9,
    newMemberId = 10,
    pcpAssignmentOverride = 11,
    duplicateOptionOverride = 12,
    homeAddressVerified = 13,
    mailingAddressVerified = 14,
    currentHomeAddressSelected = 15,
    currentMailingAddressSelected = 16,
    homeAddressAutomated = 17,
    mailingAddressAutomated = 18,
    lowConfidenceOverride = 19,
}

export interface AuditHistoryFilter {
    Start?: Date | undefined;
    End?: Date | undefined;
    GroupUsers?: any;
    Feature?: string;
}

export const AuditHistoryGridColumns = [
    {
        field: 'itemId',
        width: 300,
        headerText: 'Event ID',
        isPrimaryKey: true,
        type: 'string',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'timestamp',
        width: 200,
        headerText: 'Timestamp',
        format: 'MM/dd/yyyy hh:mm:ss a',
        type: 'date',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'userId',
        width: 200,
        headerText: 'User Id',
        type: 'string',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'eventType',
        width: 150,
        headerText: 'Event Type',
        type: 'string',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'clientIp',
        width: 100,
        headerText: 'IP Address',
        type: 'string',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'companyIds',
        width: 100,
        headerText: 'Company Ids',
        type: 'string',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
    {
        field: 'details',
        width: 'auto',
        minWidth: '300',
        headerText: 'Details',
        type: 'string',
        allowSorting: true,
        clipMode: 'EllipsisWithTooltip',
    },
];

export const trimValues = (value) => (typeof value === 'string' ? value?.trim() : value);
export const userSelectedOptionMsg =
    'With the system detecting the application type as firstValue, user has selected the secondValue option.';
export const manualSelectedOptionMsg = "System could not idetify the Application type due to no Active enrollments, User has selected the secondValue option.";
export const systemSelectedOptionMsg =
    'With the system detecting the application type as firstValue, it has automatically selected secondValue option.';
export const managerApprovedMsg = 'User requested for firstValue, Manager secondValue the request.';
export const marxOverridenMsg = 'Override is being applied because of CMS Eligibility Check has failed.';
export const myAbilityOverridenMsg = 'Override is being applied because of DSNP Eligibility Check has failed.';
export const myAbilityVerifiedMsg = 'Passed the DSNP eligibility check.';
export const myAbilityFailedMsg = 'Failed the DSNP eligibility check.';
export const cmsVerifiedMsg = 'Passed the medicare eligibility check.';
export const cmsVerificationFailedMsg = 'Failed the medicare eligibility check.';
export const uspsUserSelectionNote = 'User has selected to keep the current address for {0} address.';
export const uspsVerifiedSelectionNote = 'User has selected the {0} address.';
export const uspsAutoVerifySelectionNote = 'System automatically verified the {0}';
export const duplicateResultNote = 'Note: First, go to Page 1 tab and compare it to EZM Data Entry.  If it is an actual duplicate, then change status to Duplicate and close this work item.';
