export interface PaperEnrollmentDetails {
    page1: {
        firstName: '';
        middleInitial: '';
        lastName: '';
        birthdate: '';
        sex: '';
        emailAddress: '';
        homePhone: '';
        cellPhone: '';
        homeAddress: '';
        mailingAddress: '';
        emergencyContact: '';
        medicareInformation: '';
        consent: '';
        mbi: '';
        eligibleProgram: boolean;
        medicaidNo: '';
        plan: '';
        signedName: '';
        signedDate: '';
        authorized: boolean;
        authorizedName: '';
        authorizedAddress: '';
        authorizedPhone: '';
        authorizedRelation: '';
        streetAddress: '';
        city: '';
        state: '';
        zipCode: '';
        county: '';
        mailingAddressPO: '';
        cityPO: '';
        statePO: '';
        zipCodePO: '';
        emergencyContactOptional: '';
        relationOptional: '';
        phoneOptional: '';
    };
    page2: {
        primaryCare: '';
        providerID: '';
        medicalGroup: '';
        prescriptionDrug: boolean;
        coverageName: '';
        coverageMemberNo: '';
        coverageGroupNo: '';
    };
    page3: {
        ethnicity: {
            notHispanic;
            mexican;
            puerto;
            cuban;
            hispanic;
            notToAnswer;
        };
        race: {
            american;
            asian;
            african;
            chinese;
            filipino;
        };
        language: {
            english;
            spanish;
            vietnamese;
            chinese;
            korean;
            other;
        };
        benefits: { partbEOB; partcEOB; ANOC };
        accessibleFormat: boolean;
    };
    page4: {
        paymentOption: {
            getBill;
            automaticDeduction;
        };
        salesRepresentative: boolean;
        representativeName: '';
        npn: '';
        salesID: '';
        phone: '';
        date: '';
    };
    page5: {
        eligibility: {
            New;
            MAOEP;
            movedOutside;
            released;
            date: '';
            returnedUS;
            obtained;
            medicaidChange;
            medicaidDate: '';
            drugCoverage: '';
        };
    };
}

export interface WebEnrollmentDetails {
    //Paper enrollment data
    mbi?: string;
    sigName?: string;
    authorized?: boolean;
    authorizedName?: string;
    primaryCare?: string;
    providerId?: string;
    medicalGroup?: string;
    prescriptionDrug?: string;
    coverageGroup?: string;
    coverageMemberNo?: string;
    notHispanic?: string;
    mexican?: string;
    cuban?: string;
    hispanic?: string;
    puertoRican?: string;
    otherEthnicity?: string;
    american?: string;
    filipino?: string;
    hawaiian?: string;
    white?: string;
    asianIndian?: string;
    noRace?: string;
    othAsian?: string;
    GuamanianChamorro?: string;
    blackAfrican?: string;
    japanese?: string;
    pacific?: string;
    chineseR?: string;
    koreanR?: string;
    chinese?: string;
    korean?: string;
    samoan?: string;
    english?: string;
    otherLanguage?: string;
    spanish?: string;
    vietnamese?: string;
    braille?: string;
    print?: string;
    spouseWork?: boolean;
    youWork?: boolean;
    partCEob?: string;
    partDEob?: string;
    Anoc?: string;
    getBill?: string;
    autoDeductionRRB?: string;
    enrollmentAssist?: string;
    npn?: string;
    salesDate?: Date | undefined;
    salesPhone?: string;
    salesId?: string;
    newMedicare?: string;
    MAOEP?: string;
    movedOutside?: string;
    incarceration?: string;
    releasedOn?: Date | undefined;

    name?: string;
    id?: number;
    appDate?: Date | undefined;
    appSigDt?: Date | undefined;
    benVerifiedBy?: string;
    broker?: string;
    brokerName?: string;
    ccontEmail?: string;
    ccontname?: string;
    ccontphone?: string;
    ccontrelat?: string;
    cellPhone?: string;
    chronicCondition?: string;
    companyCode?: string;
    contractCode?: string;
    contractDescription?: string;
    created?: string;
    createdBy?: string;
    dob?: Date | undefined;
    email?: string;
    emailOptIn?: string;
    emailSentInd?: string;
    esrdInd?: string;
    ethnicity?: string;
    facilityAddress?: string;
    facilityID?: string;
    facilityName?: string;
    facilityPhone?: string;
    firstNm?: string;
    givenSOBInd?: string;
    hic?: string;
    homeAddr1?: string;
    homeAddr2?: string;
    homeCity?: string;
    homeCountyNm?: string;
    homePhone?: string;
    homeState?: string;
    homeZip1?: string;
    infoMethod?: string;
    institutionalInd?: string;
    language1?: string;
    lastNm?: string;
    lateEnrPayOption?: string;
    mailAddr1?: string;
    mailAddr2?: string;
    mailCity?: string;
    mailState?: string;
    mailZip1?: string;
    mcEffDtA?: Date | undefined;
    mcEffDtB?: Date | undefined;
    mcEffDtD?: Date | undefined;
    medicalInd?: string;
    mi?: string;
    mPropEffDt?: Date | undefined;
    newTrans?: string;
    onlineEnrollReferenceNo?: string;
    otherCoverageName?: string;
    otherEffDte?: Date | undefined;
    otherPrescriptionDrugCoverageInd?: string;
    otherRxBin?: string;
    otherRxGroup?: string;
    otherRxID?: string;
    otherRxPCN?: string;
    pbp?: string;
    pbpDescription?: string;
    pcp?: string;
    pcpFullName?: string;
    pcpSeq?: number;
    race?: string;
    receiptReference?: string;
    recordingID?: string;
    relation?: string;
    retypeYourName?: string;
    sex?: string;
    soa?: string;
    spDob?: string;
    spouseWorkStatus?: string;
    srep?: string;
    sRepName?: string;
    sRepPhone?: string;
    ssaLetterDte?: Date | undefined;
    ssn?: string;
    supplementalBuyUp?: boolean;
    title?: string;
    typeIAGREE?: string;
    typeYourName?: string;
    webAppTeleSRepID?: string;
    webAppTeleSRepName?: string;
    webAppAssistedBy?: string;
    webAppAssistedByTelephone?: string;
    workingAgeInd?: string;
    salesType?: string;
    withHoldOption?: string;
    withHoldOptionText?: string;
    ipa?: string;
    hpOption?: string;
    prevHP?: string;
    prtDEligibleDate?: Date | undefined;
    medicareIneligibilityStartDate?: Date | undefined;
    medicareIneligibilityEndDate?: Date | undefined;
    subsidyStartDate?: Date | undefined;
    subsidyEndDate?: Date | undefined;
    potentialUnCoveredMonths?: string;
    prtDPremSubsidyPercent?: string;
    copayLevel?: string;
    leadSource?: string;
    poaCons?: string;
    needHeart?: string;
    needHeartDiabetes?: string;
    needDiabetes?: string;
    lungDisorder?: string;
    haveDiabetes?: string;
    cardioVascularHeart?: string;

    // Newly Added columns
    memberId?: string;
    seq?: number;
    deceasedDt?: Date | undefined;
    mbrActive?: string;
    maritalStatus?: string;
    hpcd?: string;
    opt?: string;
    optEffDt?: Date | undefined;
    optEndDt?: Date | undefined;
    mbrEffDt?: Date | undefined;
    mTermDt?: Date | undefined;
    pbpShortDescription?: string;
    appRcptDate?: Date | undefined;
    mTrmRcptDt?: Date | undefined;
    homeCountyCd?: string;
    homeZip2?: string;
    workPhone?: string;
    mailCountyCd?: string;
    mailCountyNm?: string;
    mailZip2?: string;
    refBroker?: string;
    slead?: string;
    mcNm?: string;
    mcGender?: string;
    retiree?: string;
    retirementDate?: Date | undefined;
    employerName?: string;
    residentLongTermCareFacilityInd?: string;
    language2?: string;
    authorizedRepPhone?: string;
    disabilityInd?: string;
    hospiceInd?: string;
    currentEnrollmentStatus?: string;
    partCBeneficiaryPremium?: string;
    partDBeneficiaryPremium?: string;
    electionTypeCode?: string;
    enrollmentSourceCode?: string;
    employerSubsidyOverrideInd?: string;
    secondaryDrugInsuranceFlag?: string;
    secondaryRxId?: string;
    secondaryRxGroup?: string;
    secondaryRxPCN?: string;
    secondaryRxBin?: string;
    partDSubsidyLevelCode?: string;
    lisCd?: string;
    lisDt?: Date | undefined;
    lisTrmDt?: Date | undefined;
    tranCd?: string;
    mNote?: string;
    spFirstNm?: string;
    spLastNm?: string;
    spousedob?: string;
    cContName?: string;
    cContPhone?: string;
    cContRelat?: string;
    cContEmail?: string;
    retroDt?: Date | undefined;
    mNeedTrans?: string;
    mStairs?: string;
    mHomeEnv?: string;
    mAmbulator?: string;
    mdme?: string;
    mNmo?: string;
    heightFtIn?: number;
    weightLb?: number;
    needEscort?: string;
    uploadToEZCAP?: string;
    trmReason?: string;
    hpcdGoneTo?: string;
    lastTrrUpdate?: string;
    trnRplyCd?: string;
    prevDisabled?: string;
    bae?: string;
    lep?: string;
    lepMonths?: string;
    witholdOption?: string;
    subRepId?: string;
    teleRepId?: string;
    assistedByRep?: string;
    ahcd?: string;
    imageId?: string;
    enrlType?: string;
    webAppId?: number;
    enrollRefColor?: string;
    saDistance?: string;
    lepPHP?: string;
    ahcid?: string;
    updated?: string;
    headerColor?: string;
    optColor?: string;
    jumpStartAppt?: string;
    appointmentDt?: Date | undefined;
    planType?: string;
    pbpTransferRep?: string;
    pbpTransferBrokerId?: string;
    pbpTransferSubRep?: string;
    pbpSubRepBrokerId?: string;
    sepReasonCode?: string;
    manualSubmissionToCMS?: string;
    updatedBy?: string;
    cobSurvey?: boolean;
    premiumOption?: string;
    overrideInfoCrossingFlag?: string;
    anocStatus?: string;
    agentType?: string;
    manualSubmissionDate?: Date | undefined;
    retentionRepId?: string;
    retentionReinstatementDate?: Date | undefined;
    retentionContactDate?: Date | undefined;
    retentionDERDate?: Date | undefined;
    memberDetailsId?: string;
    age?: string;
    memberPOADetails?: any;
    endRSN?: string; // Not found in table
    communicationPreferences?: any;
    campaignCategories?: any;

    //Miscellaneous Info
    termDate?: Date | undefined;
    specialNeeds?: string;
    comprehensiveCare?: string;
    endDate?: Date | undefined;
    lepOptions?: string;
    uncoveredMonths?: string;
    srcSubsidy?: string;
    institution?: string;

    //Health Info
    brokerPayDate?: Date | undefined;
    brokerPayAmt?: string;
    ccRep?: string;
    payStamp?: string;

    //Medicare Info
    electionType?: string;
    inelligibilityEndDate?: Date | undefined;
    medicareInelligibilityEndDate?: Date | undefined;
    medicareInelligibilityStartDate?: Date | undefined;
    sendStatusDt?: Date | undefined;
    aidCode?: string;
    coverageStatus?: string;
    mcoName?: string;
    remainingShareOfCost?: string;
    medicalState?: string;
    shareOfCost?: string;
    medicaidEndDt?: Date | undefined;
    medicaidEffDate?: Date | undefined;
    effectiveDate?: Date | undefined;
    state?: string;
    repBroker?: string;
    overrideSAIPA?: string;
    userName?: string;
    isMedicaid?: boolean;

    //SNP Info
    verificationType?: string;
    revised?: string;
    snpNotes?: string;
    verificationCC?: string;
    actionTaken?: string;
    verificationDate?: Date | undefined;
    preQualifyReceivedDate?: Date | undefined;
    notifiedOfLossOfSNPMailedDt?: Date | undefined;
    potentialDERLetters2?: Date | undefined;
    receivedPreQualifyForm?: string;
    cardiovascularFailureInd?: string;
    diabetesInd?: string;
    chronicLungDisorderInd?: string;
}

export interface TeleSales {
    assistedBy?: string;
    assistedByTelephone?: string;
    assistedByTelephoneRepId?: string;
    assistedByTelephoneRepName?: string;
}

export interface MemberEnrollmentDetailsFlatModel {
    enableReasonCodeOnCheck?: boolean;
    isTriggerPcpAssignLetter?: string;
    letterReason?: string;
    letterReasonCode?: string;
    previousIpaName?: string,
    previousPcpName?: string,
    salesRepSignedDate?: string;
    isCmsValuesUpdated?: boolean;
    voiceRecordingId?: string;
    teleSales?: TeleSales;
    webAppId?: string;
    facilityId?: string;
    benVerifiedBy?: string;
    facilityAddress?: string;
    chronicCondition?: string;
    chronicValidationFlag?: boolean | undefined;
    ssaLetterDate?: Date | undefined;
    isPbpChanged?: string;
    existingPatient?: string;
    repPhone?: string;
    secondaryCoverageName?: string;
    otherRxCoverageName?: string;
    otherRxID?: string;
    otherRxGroup?: string;
    otherRxPCN?: string;
    otherRxBin?: string;
    rxEffectiveDate?: Date | undefined;
    givenSOB?: string;
    agreement?: string;
    webAppCreated?: Date | undefined;

    isSalesCodeValid?: boolean | undefined;
    marxOverride?: boolean | false;
    myAbilityOverride?: boolean;
    invalidMemberPortalId?: number | undefined;
    isValidZipCode?: boolean;
    isValidMailingZipCode?: boolean;
    searchTerm?: string;
    applicationType?: string;
    salesPbpRepSearch?: string;
    enrollmentId?: string;
    createdBy?: string;
    eTag?: string;
    infoMethod?: string;
    existingSeq?: number;
    existingDataEntryId?: number;
    language1?: string;
    residentLongTermCareFacilityInd?: boolean;
    facilityName?: string;
    facilityPhone?: string;
    salesType?: string;
    communicationPreferences?: any;
    communicationPreferenceDetails?: any;
    campaignCategories?: any;
    userName?: string;
    assistedByRep?: string;
    newTrans?: string;
    witholdOption?: string;
    srep?: string | null;
    pbpReferenceBroker?: string;
    agentType?: string;
    refBroker?: string;
    lepOptions?: string;
    tranCd?: string;
    electionType?: string;
    electionTypeCode?: string;
    sepReasonCode?: string;
    mcEffDtA?: Date | undefined;
    mcEffDtB?: Date | undefined;
    mcEffDtD?: Date | undefined;
    leadSource?: string;
    zip?: string;
    primaryPOA?: boolean;
    maritalStatus?: string;
    pbpSalesRep?: string;
    onlineEnrollReferenceNo?: string;
    isMemberVerifiedWithCMS?: boolean | false;
    isMemberVerifiedWithMedicaid?: boolean;
    schemaName?: string;
    schemaVersion?: string;
    type?: string;
    number?: string;
    files?: File[];
    assignedTo?: string;
    assignedBy?: string;
    assignedOn?: string;
    processingStatus?: string;
    notes?: Note[];
    approvalRequests?: ApprovalTicket[];
    events?: Event[];
    updatedById?: string;
    updatedByName?: string;
    updatedAt?: string | null;
    isOtherCoverage?: boolean;
    ethnicity?: KeyValueDetails[];
    race?: KeyValueDetails[];
    language?: KeyValueDetails[];
    accessibleFormat?: string;
    isWorking?: boolean;
    isSpouseWorking?: boolean;
    paymentOption?: null | string;
    isSalesRepAssist?: boolean;
    id?: string;
    createdById?: string;
    createdByName?: string;
    createdAt?: string;
    partitionKey?: string;

    // Source Details
    receivedOn?: string;
    receivedVia?: string;
    submittedBy?: null | string;
    submittedOn?: null | string;
    path?: null | string;
    fileName?: string;

    // Enrollment Details
    firstName?: string;
    middleInitial?: string | undefined;
    lastName?: string;
    birthdate?: Date | undefined;
    sex?: string;
    selfIdentify?: string;
    emailAddress?: string;
    homePhone?: string;
    cellPhone?: string;
    effectiveDate?: string;
    termDate?: string;
    mbi?: string;
    isEligibleForStateMedicalProgram?: boolean;
    // Consent
    signature?: string;
    signatureDate?: Date | undefined;
    // Medicare Information
    medicareId?: string;

    // Emergency Contact
    name?: string;
    relationship?: string;
    phone?: string;

    // Mailing Address
    mailingStreet1?: string;
    mailingStreet2?: string;
    mailingCity?: string;
    mailingState?: string;
    mailingZipCode?: string;
    mailingCounty?: string;

    // Home Address
    homeStreet1?: string;
    homeStreet2?: string;
    homeCity?: string;
    homeState?: string;
    homeZipCode?: string;
    homeCounty?: string | null;
    isMailAddrSameAsPermanent?: boolean;
    // Plan Details
    productType?: string | null;
    contractCode?: string | null;
    pbpCode?: string | null;
    pbpDescription?: null | string;
    isSNP?: boolean;
    osbPlan?: boolean;
    signedName?: null | string;
    signedDate?: Date | undefined;

    //  SalesRep Details
    salesRepName?: string;
    salesRepPhone?: string;
    npn?: string;
    salesID?: string;
    date?: Date | undefined;
    // PCP Details
    isProviderOverridden?: boolean;
    providerOverrideReason?: string;
    pcpName?: string;
    pcpId?: string;
    medicalGroup?: string;
    pcpDetails?: ProviderDetails | null;

    // Other Coverage
    otherCoverageName?: string;
    memberNumber?: string;
    groupNumber?: string;

    // Communication Preference
    partC?: boolean;
    partD?: boolean;
    anoc?: boolean;
    raceDetails?: Detail[];
    ethnicityDetails?: Detail[];
    languageDetails?: Detail[];

    //Application data
    applicationReceiptDate?: Date | undefined;
    applicationSignatureDate?: Date | undefined;
    applicationDate?: Date | undefined;

    //COORDINATION OF BENEFITS
    cobSurvey?: boolean;

    //Miscellaneous
    otherCoverage?: string;
    misOtherCoverage?: string;

    status?: string | undefined;
    soaReceived?: string;

    //new values added as per categorized model
    proposeEffectiveDate?: Date | undefined;
    medicalId?: string;
    workPhone?: string;
    authorizedName?: string;
    hpOption?: string | null;
    healthPlan?: string | null;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    memberId?: string | null;
    age?: string;
    email?: string;
    pcpid?: string;
    poaFirstName?: string;
    poaLastName?: string;
    poaRelationshipToMember?: string;
    poaPhoneNumber?: string;
    poaIsPrimaryPOA?: boolean;
    poaAddress1?: string;
    poaAddress2?: string;
    poaCity?: string;
    poaState?: string;
    poaZipCode?: string;
    poaCounty?: string;
    poaEmailAddress?: string;
    poaHomePhone?: string;
    poaWorkPhone?: string;
    poaCellPhone?: string;
    poaElectionTypeCode?: string;
    planPremiumLEPOption?: string;
    attestationOfEligibilityQuestions?: Question[];
    sendStatus?: string;
    snpPrequalificationQuestions?: SnpPrequalificationQuestions[];
    isPrequalFormReceived?: boolean;
    dateReceived?: Date | undefined;
    otherTreatingSpecialists?: string;
    specialistsName?: null;
    specialistsPhone?: null;
    isAhpAuthorizedMedicalRecordAccess?: boolean;
    isDSNP?: boolean;
    isDSNPPLan?: boolean;
    longTermCare?: string;
    ltFacilityName?: string;
    ltFacilityPhoneNumber?: string;
    plan?: string;
    enrollmentQuestions?: EnrollmentQuestion[];
    currentMedicationList?: string;
    comPref?: [];
    //page6 object
    chronicConditions?: ChronicConditions[];
    conditions?: Condition[];
    chronicConditionsVerification?: boolean;
    verificationType?: string;
    verificationDate?: Date | undefined;
    preQualificationFormReceived?: boolean;
    preQualificationReceivedDate?: Date | undefined;
    derFirstLetterMailedDate?: Date | undefined;
    derSecondLetterMailedDate?: Date | undefined;
    snpNotes?: string;
    actionTaken?: string;

    // CMS Eligibility Info
    cmsEligibilityData?: any;
    isRpcSubmission?: false | boolean;
    memberPOADetails?: POADetails[];
    isChronicCondition?: string;
    primaryPOAFlag?: boolean;
    isDuplicateCheckVerified?: boolean | false;
    isCurrentHomeAddressUsed?: boolean | false;
    isVerifiedHomeAddressUsed?: boolean | false;
    isCurrentMailingAddressUsed?: boolean | false;
    isVerifiedMailingAddressUsed?: boolean | false;
    duplicateMemberEnrollments?: DuplicateMemberEnrollments[];
}

export interface LastProviderData {
    isProviderOverridden?: boolean;
    lastProvider?: LastProvider;
    providerOverrideReason?: PcpOverrideReasons;
}

export interface LastProvider {
    pcpName?: string;
    pcpid?: string;
    medicalGroup?: string;
    details?: ProviderDetails | null;
}

export interface Conditions {
    option?: string,
    value?: boolean,
    active?: boolean,
    ordinal?: string,
}
export interface Condition {
    key?: string,
    value?: string,
}

export interface ChronicConditions {
    active?: string;
    code?: string;
    description?: string;
    fieldName?: string;
    isSelected?: boolean;
    additionalOptions?: Conditions[];
}

export interface Detail {
    isSelected: boolean | undefined;
    description: string;
    fieldName: null | string;
    code: string;
    active: boolean;
}
export interface Event {
    id: string;
    name: string;
    createdBy: string;
    createdOn: string;
}

export interface File {
    path?: string;
    fileName?: string;
    fileExt?: string;
    type?: string;
    uploadedBy?: string;
    uploadedOn?: string;
    isDeleted?: boolean;
}

export interface Note {
    id?: string;
    createdBy?: string;
    createdOn?: string;
    subject?: string;
    content?: string;
    isDeleted?: boolean;
}

export enum ApprovalTicketStatus {
    Approved = 'Approved',
    Rejected = 'Rejected',
    Deleted = 'Deleted',
}

export interface ApprovalTicket {
    id?: string;
    requestType: ApprovalRequestTypes;
    requestedBy?: string;
    requestedOn?: string;
    requestNotes?: string;
    assignedTo?: string | null;
    updatedBy?: string;
    updatedOn?: string;
    decisionNotes?: string;
    status?: string;
    isSystematic?: boolean;
    enrollmentId?: string;
}

export interface ProviderDetails {
    pcpFirstName?: string;
    pcpLastName?: string;
    suffix?: string;
    gender?: string;
    mseq?: number | null;
    pseq?: number;
    type?: string;
    effectiveDate?: string;
    termDate?: string | null;
    inNetwork?: boolean;
    nonValidatedProvider?: boolean;
    isMedicaid?: boolean;
    closedPanel?: boolean;
    isExistingPt?: boolean;
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    phone?: string;
    ipa?: string;
    distance?: number;
    transferReason?: string;
    authorizedBy?: string;
    authorizedOn?: string;
    panelDescription?: string;
    overridRequirement?: string;
    enrollmentId?: string;
    isTriggerPcpAssignLetter?: string;
    letterReason?: string;
    letterReasonCode?: string;
}

export interface KeyValueDetails {
    key: string;
    value: string;
}

export interface MemberEnrollmentDetails {
    existingSeq?: number;
    existingDataEntryId?: number;
    memberId?: null | string;
    eTag?: string;
    assignedTo?: string;
    enrollmentId?: null | string;
    createdBy?: null | string;
    createdByName?: null | string;
    createdAt?: string;
    processingStatus?: string;
    updatedBy?: null | string;
    updatedAt?: null | string;
    notes?: Note[];
    approvalRequests?: ApprovalTicket[];
    files?: File[];
    communicationPreferenceDetails?: CommunicationPreference[];
    enrollmentQuestions?: EnrollmentQuestion[];
    chronicConditions?: ChronicConditions[];
    page1?: Page1;
    page2?: Page2;
    page3?: Page3;
    page4?: Page4;
    page5?: Page5;
    page6?: Page6;
    ethnicityDetails?: Detail[];
    raceDetails?: Detail[];
    languageDetails?: Detail[];
    attestationOfEligibilityQuestions?: Question[];
    onlineEnrollReferenceNo?: string;
    cmsEligibilityData?: any;
    isRpcSubmission?: false | boolean;
    teleSales?: TeleSales;
    voiceRecordingId?: string;
}

export interface CommunicationPreference {
    description: string;
    fieldName: string | null;
    code: string;
    active: string;
    isSelected: boolean;
    partC?: boolean;
    partD?: boolean;
    anoc?: boolean;
}

export interface SnpPrequalificationQuestions {
    questionNumber: number;
    questionTitle: string;
    text: string;
    controlType: string;
    controlOptions: ControlOption[];
    value: string;
    section: string;
    additionalQuestions: string;
    PartitionKey: string;
    CreatedById: string;
    CreatedByName: string;
    CreatedAt: string;
    LastUpdatedById: string;
    LastUpdatedByName: string;
    LastUpdatedAt: string;
    id: string;
}

export interface ControlOption {
    key?: string;
    value?: string;
}

export interface Page1 {
    applicationType?: string;
    marxOverride?: boolean | false;
    myAbilityOverride?: boolean | false;
    firstName?: string;
    middleInitial?: string;
    lastName?: string;
    birthdate?: Date | undefined;
    sex?: string;
    selfIdentify?: string;
    signature?: string;
    signatureDate?: Date | undefined;
    MedicareId?: null | string;
    isEligibleForStateMedicalProgram?: boolean;
    productType?: string | null;
    contractCode?: string | null;
    pbpCode?: string | null;
    pbpDescription?: string | null;
    plan?: string;
    isSNP?: boolean;
    isDSNP?: boolean;
    hpOption?: null | string;
    healthPlan?: null | string;
    medicareId?: null | string;
    medicalId?: string;
    proposeEffectiveDate?: Date | undefined;
    homeAddress?: Address;
    mailingAddress?: Address;
    isMemberVerifiedWithCMS?: boolean;
    isMemberVerifiedWithMedicaid?: boolean;
    isMailAddrSameAsPermanent?: boolean;
    //New Fields added by syed gufran
    osbPlan?: boolean;
    isSignedByAuthorisedRep?: boolean;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    memberId?: string;
    age?: string;
    isDuplicateCheckVerified?: boolean;
}

export interface ApplicationData {
    applicationReceiptDate?: Date | undefined;
    applicationSignatureDate?: Date | undefined;
    applicationDate?: Date | undefined;
    givenSOB?: string;
    agreement?: string;
    webAppCreated?: Date | undefined;
}

export interface EmergencyContact {
    name?: string;
    relationship?: string;
    phone?: string;
    email?: null | string;
}

export interface Address {
    address1?: string;
    address2?: null | string;
    city?: null | string;
    state?: null | string;
    zipCode?: null | string;
    county?: null | string;
    emailAddress?: null | string;
    homePhone?: null | string;
    workPhone?: null | string;
    cellPhone?: null | string;
    isCurrentAddressUsed?: boolean | false;
    isVerifiedAddressUsed?: boolean | false;
}

export interface Page2 {
    cobSurvey?: boolean;
    maritalStatus?: null | string;
    miscellaneous?: Miscellaneous;
    pcpAssignment?: PcpAssignment;
    otherCoverage?: boolean;

    //New Fields added by syed gufran
    isWorking?: boolean;
    isSpouseWorking?: boolean;

    emergencyContact?: EmergencyContact;
    applicationData?: ApplicationData;
    lastProviderDetails?: LastProviderData | null;
}

export interface Miscellaneous {
    otherCoverage?: string;
    status?: null | string;
    soaReceived?: null | string;
}

export interface PcpAssignment {
    pcpName?: string;
    pcpid?: string;
    medicalGroup?: null | string;
    isTriggerPcpAssignLetter?: string;
    letterReason?: string,
    letterReasonCode?: string,
    details?: ProviderDetails | null;
    previousIpaName?: string,
    previousPcpName?: string,
}

export interface Page3 {
    infoMethod?: string;
    communicationPreference?: any;
    longTermCare?: null | string;
    ltFacilityName?: string;
    ltFacilityPhoneNumber?: string;
    assistedByRep?: string;
    maritalStatus?: string;
    accessibleFormat?: string;
    languageDetails?: Detail[];
    isChronicCondition?: string;
    facilityId?: string;
    benVerifiedBy?: string;
    ssaLetterDate?: Date | undefined;
    facilityAddress?: string;
    chronicCondition?: string;
}

export interface Page4 {
    salesRepSignedDate?: string;
    newOrTransfer?: null | string;
    salesRepId?: null | string;
    referenceBroker?: null | string;
    pbpSalesRep?: null | string;
    pbpReferenceBroker?: null | string;
    witholdOption?: null | string;
    salesType?: string;

    //New Fields added by syed gufran
    agentType?: null | string;
    pbpTransferBrokerId?: string;
    refBroker?: string;
    planPremiumLEPOption?: string;
    paymentOption?: string | null;
    isSalesRepAssist?: boolean;
    salesRepName?: string;
    npn?: string;
    salesID?: string;
    phone?: string;
    newTrans?: string;
    srep?: string | null;
    pbpTransferRep?: string;
    lepOptions?: string;
    salesRepPhone?: string;
}

export interface Page5 {
    poa?: POADetails[];
    sendStatus?: string;
    electionType?: null | string;
    sepReasonCode?: null | string;
    leadSource?: null | string;
    mcEffDtA?: Date | undefined;
    mcEffDtB?: Date | undefined;
    mcEffDtD?: Date | undefined;
    PartAEffectiveDate?: Date | undefined;
    PartBEffectiveDate?: Date | undefined;
    PartDEffectiveDate?: Date | undefined;
    rxEffectiveDate?: Date | undefined;
    secCoverageName?: string;
    otherRxCoverageName?: string;
    otherRxID?: string;
    otherRxGroup?: string;
    otherRxPCN?: string;
    otherRxBin?: string;
    //enrollmentQuestions?: EnrollmentQuestion[];
}
export interface EnrollmentQuestion {
    id?: number;
    text?: string;
    value?: boolean;
    section?: string;
    additionalQuestions?: AdditionalQuestion[] | null | string;
    questionNumber?: number;
}

export interface Poa {
    firstName?: string;
    lastName?: string;
    relationshipToMember?: string;
    phoneNumber?: string;
    isPrimaryPOA?: boolean;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    county?: string;
    emailAddress?: string;
    homePhone?: string;
    workPhone?: string;
    cellPhone?: string;
    electionTypeCode?: string;
}

export interface Page6 {
    chronicConditionsVerification?: boolean;
    verificationType?: string;
    verificationDate?: Date | undefined;
    preQualificationFormReceived?: boolean;
    preQualificationReceivedDate?: Date | undefined;
    derFirstLetterMailedDate?: Date | undefined;
    derSecondLetterMailedDate?: Date | undefined;
    snpNotes?: string;
    actionTaken?: string;
    snpPrequalificationQuestions?: SnpPrequalificationQuestions[];
    conditions?: Condition[];
}

export interface Question {
    questionNumber?: number;
    text?: string;
    value?: boolean;
    section?: string;
    additionalQuestions?: AdditionalQuestion[];
    id?: string;
    createdById?: string;
    createdByName?: string;
    createdAt?: string;
    lastUpdatedById?: string;
    lastUpdatedByName?: string;
    lastUpdatedAt?: string;
}

export interface AdditionalQuestion {
    pqNumber?: number | undefined;
    questionNumber?: number | undefined;
    text?: string;
    value?: string;
    section?: string;
    controlName?: string;
}

export interface MemberEnrollment {
    id: number;
    avaWorkItemId: number;
    firstName: string;
    lastName: string;
}

export enum MemberEnrollmentFeatureFlags {
    ManagerApproval = 'ManagerApproval',
    PCPSearch = 'PCPSearch',
    AutoRefreshWorkList = 'AutoRefreshWorkList',
}

export enum ApprovalRequestTypes {
    NewMemberId = 'NewMemberId',
    PCPAssignmentOverride = 'PCPAssignmentOverride',
    AttachLowMediumConfidenceMember = 'AttachLowMediumConfidenceMember',
}

export enum PCPDetailsAssignmentTypes {
    PcpAssignmentAllowed = 0,
    PcpAssignmentNotAllowedClosedPanel = 1,
    PcpAssignmentNotAllowedConditional = 2,
    PcpAssignmentNotAllowedAddressType = 3,
}

export enum PcpOverrideReasons {
    NotAvailable = 0,
    Terminated = 1,
    NonContracted = 2,
    ClosedPanel = 3,
    NotInNetwork = 4,
    NotInMemberServiceArea = 5,
    Over35Miles = 6,
    ClosedPanelOverride = 7,
    NoPcpAssigned = 8,
}

export interface ProviderSearchParameters {
    companyId?: string | null;
    providerId?: string | null;
    npi?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    ipa?: string | null;
    class?: string | null;
    specialty?: string | null;
    providerCategory?: string | null;
    language?: string | null;
    searchTerm?: string | null;
    fromAddress?: string | null;
    withinMiles?: string | null;
    acceptingNewPatients?: string | null;
    gender?: string | null;
    lineOfBusiness?: string | null;
    planYear?: string | null;
    pbpId?: string | null;
    healthPlanId?: string | null;
    visitType?: string | null;
    hospitalService?: string | null;
    sortBy?: string | null;
    pageIndex?: number;
    pageSize?: number;
    taxId?: string | null;
    directoryType?: string | null;
    medGrpNetwork?: string | null;
    totalNpiCount: number | null;
    pcpType?: string;
    rating?: string;
}

export interface PCPDetailsRequest {
    companyCode?: string;
    provid?: string;
    addressType?: string;
    street?: string;
    zip?: string;
    phone?: string;
    isAcceptingNewPatients?: string;
    healthPlanId?: string;
    planYear?: string;
}

export interface IsActiveIPARequest {
    ipaCode: string;
    effectiveDate: string;
    contractCode: string;
    pbp: string;
}

export interface IsActiveIPAResponse {
    isValid: boolean;
    errorMessage: string;
}

export enum EnrollmentProcessingStatus {
    New = 'New',
    InProgress = 'InProgress',
    WaitingForInfo = 'WaitingForInfo',
    RequestForInfo = 'RequestForInfo',
    Completed = 'Completed',
    RejectedAsDuplicate = 'RejectedAsDuplicate',
    InvalidMember = 'InvalidMember',
    ManagerApprovalRequired = 'ManagerApprovalRequired',
    Void = 'Void',
}

export enum ApplicationTypes {
    ContractChange = 'ContractChange',
    PbpChange = 'PbpChange',
    MultipleTransaction = 'MultipleTransaction',
    NewMemberId = 'NewMember',
    DuplicateEnrollment = 'DuplicateEnrollment',
    NewEnrollment = 'NewEnrollment',
}

export interface tabDetails {
    tabId?: string;
    actionName?: string | null;
    actionType?: string | null;
    showValidationDetails: boolean | false;
}

export enum messageType {
    Success = 1,
    Failure = 0,
}

export interface tMessage {
    text?: string;
    error?: any;
    type: messageType;
    refreshTabs?: number;
    tabId?: string;
}
export interface tState {
    stateValue: Partial<MemberEnrollmentDetailsFlatModel>;
    tabId?: string;
}
export interface SubmitEnrollmentResponse {
    memberId?: string;
    dataEntryId?: number;
    pbpCode?: string | null;
    pbpDescription?: string | null;
    referenceNumber?: string | null;
    applicationType?: string | null;
    contarctCode?: string | null;
    dob?: string | null;
    fullName?: string | null;
    isSuccess: boolean | false;
    isMemberVerified: boolean | false;
    isDuplicateRecord: boolean | false;
    createdDate?: string | null;
    createdBy?: string | null;
    productType?: string | null;
    eTag?: string;
    isDisEnrolled: boolean | false;
}
export enum SubmissionType {
    SubmitInvalidMemberPortal = 0,
    SubmitValidMemberPortal = 1,
}

export interface CMSEligibility {
    transactionDate: string;
    mbdLoadEffDate: Date;
    requestMedID: string;
    requestLastName: string;
    requestDOB: string;
    foundMedID: string;
    foundNameOrDOB: string;
    inquiryResponse: string;
    medID: string;
    lastName: string;
    firstName: string;
    middleInitial: string;
    genderCd: string;
    raceCd: string;
    birthDate: string;
    partAEntitlementDate: string;
    partAEntitleEndDate: string;
    partBEntitlementDate: string;
    partBEntitleEndDate: string;
    stateCd: string;
    countyCd: string;
    hospiceStatus: string;
    hospiceStartDate: string;
    hospiceEndDate: string;
    institutionalizedStatus: string;
    institutionalizedStartDate: string;
    institutionalizedEndDate: string;
    esrdStatus: string;
    esrdStartDate: string;
    esrdEndDate: string;
    medicaidStatus: string;
    medicaidStartDate: string;
    medicaidEndDate: string;
    livingStatus: string;
    deathDate: string;
    xrefHicnNumber: null;
    enrollmentInfo: EnrollmentInfo[];
    potentialUncvrdMths: string;
    potentialUncvrdMthsEffDate: string;
    partDEligibleDate: string;
    listInfo: ListInfo[];
    partDHistoryInfo: PartDHistoryInfo[];
    nuncMoInfo: NuncMoInfo[];
    rdsHistoryInfo: any[];
    medicareInEligibilityInfo: any[];
    priorEnrollmentInfo: PriorEnrollmentInfo[];
    medicareBeneficiaryIdentifiers: any[];
}

export interface EnrollmentInfo {
    planIdField: string;
    pbpField: string;
    eghpField: string;
    planTypeCodeField: string;
    planEnrollmentDateField: string;
    drugPlanIndField: string;
    enrollSourceCode: string;
}

export interface ListInfo {
    subsidyStartDateField: string;
    subsidyEndDateField: string;
    copayLevelField: string;
    partDPremSubPctField: string;
}

export interface NuncMoInfo {
    uncovMthsStartDateField: string;
    uncovMthsField: string;
    uncmoIndField: string;
    totUncovMthsField: string;
}

export interface PartDHistoryInfo {
    partDStartDateField: string;
    partDEndDateField: string;
}

export interface PriorEnrollmentInfo {
    priorPlanIdField: string;
    priorPBPField: string;
    priorEGHPField: string;
    priorPlanTypeCodeField: string;
    priorPlanEnrollmentDateField: string;
    priorPlanEnrollmentEndDate: string;
    priorDrugPlanIndField: string;
    priorEnrollSourceCode: string;
}

export interface ModifiedMedicalEligibility {
    medicaidEffDt?: Date | undefined;
    medicaidEndDt?: Date | undefined;
    shareofCost?: string;
    remainingShareofCost?: string;
    state?: string;
    MCOName?: string;
    aidCode?: string;
    memberCoverageStatus?: string;
    medicaidNumber?: string;
}
export interface POADetails {
    id?: number;
    firstName?: string;
    mi?: string;
    lastName?: string;
    relationshipToMember?: string;
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    phoneNumber?: string;
    cellPhone?: string;
    createdBy?: string;
    createdOn?: Date | string | undefined;
    changedBy?: string;
    changedOn?: Date | string | undefined;
    fax?: string;
    isPrimaryPOA?: boolean | false;
    status?: string;
    type?: string;
    expiration?: Date | undefined;
}

export interface DuplicateMemberEnrollments {
    rowID?: string;
    dataEntryID?: number;
    memberID?: string;
    sequence?: number;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    sex?: string;
    dob?: string;
    medicareID?: string;
    currentEnrollmentStatus?: string;
    trmReason?: string;
    effectiveDate?: string;
    termDate?: string;
    matchFields?: string;
    unmatchedFields?: null;
    needsManagerOverride?: string;
    levelOfConfidence?: string;
    maxSeq?: number;
    contractCode?: string;
    overrideReason?: string;
    isContractTransfer?: boolean;
    isPbpChange?: boolean;
    isMultipleOrDuplicate?: boolean;
    isNewEnrollment?: boolean;
    isAllApplicationTypeEnabled?: boolean;
    pbp?: string;
    sRep?: string;
    refBroker?: string;
    appSigDt?: string;
    tranCd?: string;
    isActiveRecord?: boolean;
    productType?: string;
}

export interface ChangeHistoryDetails {
    id: string;
    schemaName: string;
    objectId: string;
    objectName: string;
    lastUpdatedByName: string | null;
    lastUpdatedAt: string | null;
    differences: ChangeHistoryFields[];
}

export interface ChangeHistoryFields {
    name: string;
    type: string;
    currentValue: string | null;
    newValue: string;
}

export interface ViewChangeHistory {
    name: string | null;
    currentValue: string | null;
    newValue: string | null;
    lastUpdatedByName: string | null;
    lastUpdatedAt: string | null;
}
export interface DuplicateResult {
    duplicateMemberDetails: DuplicateMemberDetails[],
    eTag: string;
}
export interface DuplicateMemberDetails {
    isContractTransfer: boolean;
    isPbpChange: boolean;
    isMultipleOrDuplicate: boolean;
    isNewEnrollment: boolean;
    memberID: string;
    sequence: number;
    dataEntryID: number;
    sRep: string;
    refBroker: string;
    levelOfConfidence: string;
    matchFields: string;
    isAllApplicationTypeEnabled: boolean;
    termDate: string;
}

export type UspsAddressRequest = {
    street1: string;
    street2: string | null;
    city: string;
    state: string;
    zipCode: string;
};

export type VerifiedAddress = {
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zip5: string;
};

export type originalAddresses = {
    permanentAddress: UspsAddressRequest;
    mailingAddress: UspsAddressRequest | null;
}
export type verifiedAddresses = {
    permanentAddress: AddressValidationResponse;
    mailingAddress: AddressValidationResponse | null;
}

export type AddressDetails = {
    originalAddress: originalAddresses;
    verifiedAddress: verifiedAddresses;
    isMailAddrSameAsHomeAddr: boolean;
    userOption: AddressValidatioUserOption;
}

export type AddressValidationResponse = {
    returnText: string;
    verifiedAddress: VerifiedAddress | null;
    hasAddressDiscrepancy: boolean;
    autoAssignUspsAddress: boolean;
};

export type AddressValidatioUserOption = {
    isVerifiedPermanentAddressTaken: boolean;
    isUserPermanentAddressTaken: boolean;
    isVerifiedMailAddressTaken: boolean;
    isUserMailAddressTaken: boolean;

};
export enum AddressType {
    Permanent = 1,
    Mailing = 2,
}
export enum SelectionType {
    OriginalAddress = 1,
    VerifiedAddress = 2,
}