import React from 'react';
import { Query } from '@syncfusion/ej2-data';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
    CheckBoxSelection,
    Inject,
    MultiSelectComponent,
    DropDownListComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { get, isNil } from 'lodash';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import { map, tap } from 'rxjs/operators';
import { ajaxGet } from 'common/ajax';
import FiltersPanelSkeleton from 'components/Common/FiltersPanelSkeleton';
import ProgressButton from 'components/Common/Wrappers/ProgressButton';
import { makeId } from './FiltersHelpers';
import MultiSelectTemplate from './MultiSelectTemplate';
import { pagingSize } from '../../common/filterOptions';
import { filterControlType } from '../../common/widgets';
import { filtersChangedService } from '../../subjects/common/FiltersService';

//Styles
import './FiltersPanel.css';

const defaultWidth = '200px';

class FiltersPanel extends React.Component {
    constructor(props) {
        super(props);
        this.dynamicFilters = props.dynamicFilters;
        this.filters = { ...props.initialValues };
        this.panelId = makeId(3);
        this.ipaOrgMap = {};
        this.filtersButton = null;
        this.state = {
            filterInputs: null,
            count: 0,
            ipaData: null,
            errorList: [],
        };
    }

    componentDidMount() {
        //check if contains IPAs
        const filters = this.props.staticFilters;
        if (filters.some((element) => element.id === 'IPAs') && !this.state.ipaData) {
            this.loadOrganizations();
        } else {
            const filters = this.constructFilterComponents(this.props.staticFilters);
            this.setState({
                filterInputs: filters,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const filterCompare = (filtersA, filtersB) => {
            return JSON.stringify(filtersA) === JSON.stringify(filtersB);
        };
        if (
            !filterCompare(this.props?.staticFilters, prevProps?.staticFilters) ||
            this.props?.initialValues !== prevProps?.initialValues ||
            this.state.ipaData !== prevState.ipaData
        ) {
            this.setState({
                filterInputs: this.constructFilterComponents(this.props.staticFilters),
            });
        }
    }

    getDefaultValue(filter, isDatePicker = false) {
        if (!isDatePicker) {
            if (!isNil(this.props.initialValues?.[filter.id])) {
                return this.props.initialValues[filter.id];
            } else if (!isNil(filter.defaultValue)) {
                return filter.defaultValue;
            } else {
                return null;
            }
        } else {
            return this.props.initialValues && this.props.initialValues[filter.id]
                ? new Date(this.props.initialValues[filter.id])
                : filter.defaultValue
                ? new Date(filter.defaultValue)
                : null;
        }
    }

    getDefaultValueForSelection(filter) {
        if (!isNil(this.props.initialValues?.[filter.id])) {
            return this.props.initialValues[filter.id];
        } else if (!isNil(filter.defaultValue)) {
            return filter.defaultValue;
        }
    }

    constructFilterComponents(filters) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const filterInputs = [];
        let tabindex = 1;
        const onSelectedChange = this.onFilterValueChange;
        filters.forEach(function (element, index) {
            let inputControl = null;
            const parameters = element.parameters || {};
            const placeholder = parameters.placeHolder || element.label;
            const panelId = self.panelId + '_' + index;
            const onItemSelectedChange = parameters.onItemSelectedChange || onSelectedChange;
            if (element.controlType === filterControlType.MultiSelect) {
                let multiSelectInstance = React.createRef();
                inputControl = (
                    <MultiSelectTemplate
                        type={parameters.type || element.type}
                        elementId={element.id}
                        ref={(refInstance) => (multiSelectInstance = refInstance)}
                        panelid={panelId}
                        placeHolder={placeholder}
                        onChange={onItemSelectedChange}
                        onDestroy={() => self.onDeselectFilter(element.id)}
                        maximumSelectionLength={parameters.maxSelectionLength}
                        data={element.id === 'IPAs' ? self.state.ipaData : element.data}
                        observableData={element.observableData}
                        fields={element.fields}
                        filterWithValue={element.filterWithValue}
                        filterQuery={element.filterQuery}
                        itemTemplate={element.itemTemplate}
                        defaultValue={self.getDefaultValue(element) || []}
                        onSelected={parameters.onSelected}
                        popupWidth={element.popupWidth}
                        width={element.width || defaultWidth}
                        allowFiltering={element.allowFiltering}
                        allowPaging={parameters.allowPaging}
                        tabIndex={tabindex++}
                        showSelectAll={isNil(element.showSelectAll) ? true : element.showSelectAll}
                        pagingSize={element.pagingSize}
                        omitLazyLoadingOnScroll={element.omitLazyLoadingOnScroll}
                    />
                );
            } else if (element.controlType === filterControlType.TextBox) {
                let textBoxInstance = React.createRef();
                inputControl = (
                    <TextBoxComponent
                        name={element.id}
                        ref={(textBox) => (textBoxInstance = textBox)}
                        placeholder={placeholder}
                        width={element.width || defaultWidth}
                        change={() => onItemSelectedChange(textBoxInstance)}
                        destroy={() => self.onDeselectFilter(element.id)}
                        value={self.getDefaultValue(element)}
                        showClearButton={!element.readonly}
                        disabled={element.disabled === true || false}
                        readonly={element.readonly}
                        htmlAttributes={parameters.htmlAttributes}
                        tabIndex={tabindex++}
                    />
                );
            } else if (element.controlType === filterControlType.NumericTextBox) {
                let numericTextBoxInstance = React.createRef();
                inputControl = (
                    <NumericTextBoxComponent
                        name={element.id}
                        ref={(textBox) => (numericTextBoxInstance = textBox)}
                        showClearButton={true}
                        showSpinButton={parameters.showSpinButton === undefined ? true : parameters.showSpinButton}
                        placeholder={placeholder}
                        width={element.width || defaultWidth}
                        max={parameters.max ? (parameters.max === 'none' ? null : parameters.max) : 150}
                        min={parameters.min ? (parameters.min === 'none' ? null : parameters.min) : 0}
                        format={parameters.format || 'n2'}
                        decimals={0}
                        change={() => onItemSelectedChange(numericTextBoxInstance)}
                        destroy={() => self.onDeselectFilter(element.id)}
                        value={self.getDefaultValue(element)}
                        tabIndex={tabindex++}
                    />
                );
            } else if (element.controlType === filterControlType.CheckBox) {
                let checkBoxInstance = React.createRef();
                inputControl = (
                    <CheckBoxComponent
                        name={element.id}
                        ref={(checkBox) => (checkBoxInstance = checkBox)}
                        label={element.label}
                        labelPosition="After"
                        change={() => onItemSelectedChange(checkBoxInstance)}
                        destroy={() => self.onDeselectFilter(element.id)}
                        value={self.getDefaultValue(element)}
                        checked={self.getDefaultValue(element)}
                        tabIndex={tabindex++}
                    />
                );
            } else if (element.controlType === filterControlType.DatePicker) {
                let datePickerInstance = React.createRef();
                inputControl = (
                    <DatePickerComponent
                        name={element.id}
                        strictMode={true}
                        ref={(datePicker) => (datePickerInstance = datePicker)}
                        placeholder={placeholder}
                        dayHeaderFormat="Short"
                        floatLabelType="Never"
                        showClearButton={true}
                        max={parameters.maximumDate}
                        start={parameters.start}
                        depth="Month"
                        format="MM/dd/yyyy"
                        width={element.width || defaultWidth}
                        change={() => onItemSelectedChange(datePickerInstance)}
                        destroy={() => self.onDeselectFilter(element.id)}
                        value={self.getDefaultValue(element, true)}
                        tabIndex={tabindex++}
                        open={() => {
                            const initView = parameters.initialCalendarView;
                            if (initView?.setInitialView) {
                                const startDate = new Date();
                                startDate.setFullYear(startDate.getFullYear() + initView.addYears);
                                datePickerInstance.navigateTo(parameters.start || 'Decade', startDate);
                            }
                        }}
                    />
                );
            } else if (element.controlType === filterControlType.Selection) {
                let dropDownInstance = React.createRef();
                inputControl = (
                    <DropDownListComponent
                        name={element.id}
                        placeholder={placeholder}
                        delayUpdate={element.delayUpdate}
                        ref={(dropDown) => (dropDownInstance = dropDown)}
                        change={() => onItemSelectedChange(dropDownInstance)}
                        fields={element.fields}
                        dataSource={element.data}
                        width={element.width || defaultWidth}
                        popupHeight="400px"
                        popupWidth="350px"
                        defaultValue={element.defaultValue}
                        allowFiltering={get(element, 'allowFiltering', true)}
                        showDropDownIcon={true}
                        showClearButton={get(element, 'showClearButton', true)}
                        filterType="Contains"
                        itemTemplate={
                            element.showTooltipValue ? self.templateItemTooltip : element.itemTemplate || null
                        }
                        valueTemplate={element.valueTemplate || null}
                        value={self.getDefaultValueForSelection(element)}
                        tabIndex={tabindex++}
                        query={parameters.allowPaging && new Query().take(parameters.pagingSize ?? pagingSize)}
                        filtering={(args) => {
                            let query = new Query().where(element.fields.text, 'contains', args.text, true);
                            if (parameters.allowPaging) {
                                query = query.take(parameters.pagingSize ?? pagingSize);
                            }
                            args.updateData(element.data, query);
                        }}
                        select={parameters.onItemSelect && parameters.onItemSelect.bind(this)}
                    />
                );
            } else if (element.controlType === filterControlType.RadioButton) {
                self.filters[element.id] = parameters.default?.value;
                inputControl = (
                    <div>
                        {element.datalist.map((item) => (
                            <RadioButtonComponent
                                name={element.id}
                                change={() => onItemSelectedChange({ name: element.id, value: item.value })}
                                label={item.label}
                                value={item.value}
                                checked={parameters.default.label === item.label}
                                key={`${element.id}+'_'+${item.label}`}
                                tabIndex={tabindex++}
                            />
                        ))}
                    </div>
                );
            } else if (element.controlType === filterControlType.DateRangePicker) {
                if (element.defaultValue) self.filters[element.id] = element.defaultValue;
                let dateRangePickerInstance = React.createRef();
                inputControl = (
                    <DateRangePickerComponent
                        ref={(dateRangePicker) => (dateRangePickerInstance = dateRangePicker)}
                        name={element.id}
                        placeholder={placeholder}
                        strictMode={true}
                        format={'MM/dd/yyyy'}
                        width={element.width || defaultWidth}
                        maxDays={element.maxDays}
                        change={() => onItemSelectedChange(dateRangePickerInstance)}
                        value={self.getDefaultValue(element)}
                        delayUpdate={true}
                        tabIndex={tabindex++}
                    />
                );
            } else if (element.controlType === filterControlType.sectionHeader) {
                filterInputs.push(
                    <div key={panelId} style={{ ...element.style }}>
                        {element.text}
                    </div>,
                );
                return;
            } else if (element.controlType === filterControlType.GoogleAutoComplete) {
                inputControl = (
                    <Autocomplete
                        id={element.id}
                        onPlaceSelected={(place) => {
                            // first address means either zipcode or name of the city depending on the users entry
                            onItemSelectedChange({
                                ...place,
                                name: element.id,
                                value: place
                                    ? place.address_components
                                        ? place.address_components[0].long_name
                                        : null
                                    : null,
                            });
                        }}
                        options={{
                            types: element.types,
                            componentRestrictions: element.componentrestrictions,
                        }}
                        placeholder={element.placeHolder}
                        style={{ height: element.height, width: element.width }}
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        tabIndex={tabindex++}
                    />
                );
            }

            filterInputs.push(
                <div
                    key={element.id + '_control_container'}
                    id={element.id + '_control_container'}
                    className="filter-input"
                    style={element.style}
                    onKeyDown={self.props.handleEnterKeyAsSearch && self.handleEnterKeyAsSearch}
                >
                    <TooltipComponent
                        position="TopCenter"
                        content={element.tooltipText || element.label}
                        target={'#' + element.id + '_contents_container'}
                    >
                        {<div id={element.id + '_contents_container'}>{inputControl}</div>}
                    </TooltipComponent>
                </div>,
            );
        });
        return filterInputs;
    }

    handleEnterKeyAsSearch = (args) => {
        if (args.key === 'Enter') {
            args?.target && this.onFilterValueChange(args.target);
            this.onClickSearch();
        }
    };

    onDeselectFilter(filterName) {
        if (this.props.enableSoftReset) {
            return;
        } else if (this.filters.hasOwnProperty(filterName)) {
            delete this.filters[filterName];
        }
    }

    onDynamicFiltersChange = (args) => {
        const selectedDynamicFilters = this.dynamicFilters.filter((value) => args.value.includes(value.id));
        const filters = this.props.staticFilters.concat(selectedDynamicFilters);
        this.setState({
            filterInputs: this.constructFilterComponents(filters),
        });
    };

    initializeRadio(filter) {
        this.filters[filter.name] = filter.value;
    }

    onFilterValueChange = (filter) => {
        if (!filter) return;
        var curFilters = null;
        if (this.filters) {
            curFilters = Object.assign({}, this.filters);
        } else {
            curFilters = {};
        }

        //All filters except checkbox value is at filter.value
        let result = filter.value;
        //CheckBox event values come from filter.checked
        if (filter.element && filter.element.type === 'checkbox') {
            result = filter.checked;
        }
        curFilters[filter.name] = result;
        if (filter.name === 'IPAs') {
            const orgIds = [];
            for (const referenceId of filter.value) {
                orgIds.push(this.ipaOrgMap[referenceId].organizationId);
            }
            curFilters['PCPs'] = [];
            filtersChangedService.change(this.panelId, 'OrgIds', orgIds);
        } else {
            filtersChangedService.change(this.panelId, filter.name, result);
        }

        this.filters = curFilters;
    };

    DynamicFiltersSelectButton() {
        return (
            <div id="filter-selector">
                <MultiSelectComponent
                    dataSource={this.dynamicFilters}
                    fields={{
                        text: 'label',
                        value: 'id',
                    }}
                    change={this.onDynamicFiltersChange}
                    placeholder="Add More Filters..."
                    width="180px"
                    popupHeight="400px"
                    popupWidth="280px"
                    sortOrder="Ascending"
                    mode="CheckBox"
                    showSelectAll={true}
                    allowFiltering={true}
                    showDropDownIcon={true}
                    showClearButton={true}
                    filterType="Contains"
                    cssClass="advancedSearchDynamicFilterContainer"
                >
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </div>
        );
    }

    clearFilters = () => {
        this.filters = this.props.preserveInitialValuesOnClear === true ? { ...this.props.initialValues } : {};
        this.props.onClearFilters && this.props.onClearFilters();
        this.setState((prevState) => ({ count: prevState.count + 1 }));
    };

    onClickSearch = () => {
        if (this.props.validateFilters) {
            const currentErrors = this.props.validateFilters(this.filters);
            if (currentErrors.length > 0) {
                this.setState({
                    errors: currentErrors,
                });
                this.stopSpinner();
                return;
            } else {
                this.setState({
                    errors: [],
                });
            }
        }

        if (this.props.displaySpinner) {
            this.props.onClickSearch(this.filters, this.stopSpinner);
        } else {
            this.stopSpinner();
            this.props.onClickSearch(this.filters);
        }
    };

    stopSpinner = () => {
        this.filtersButton && this.filtersButton.progressComplete();
    };

    startSpinner = () => {
        this.filtersButton && this.filtersButton.start();
    };

    loadOrganizations = () => {
        this.subscription = ajaxGet('/odata/sessionorganizations?OrgType=IPA&orderby=name&$top=10000')
            .pipe(
                map((args) => {
                    const data = args.response.value;
                    data.sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    });
                    return data;
                }),
                tap((organizationArray) => {
                    const orgMap = {};
                    for (var organization of organizationArray) {
                        orgMap[organization.referenceId] = organization;
                    }
                    this.ipaOrgMap = orgMap;
                    const ipas = organizationArray.map((organization) => ({
                        referenceId: organization.referenceId,
                        name: `${organization.referenceId} - ${organization.name}`,
                    }));
                    this.setState({
                        ipaData: ipas,
                    });
                }),
            )
            .subscribe();
    };

    componentWillUnmount() {
        this.subscription && this.subscription.unsubscribe();
    }

    templateItemTooltip(item) {
        return (
            <div key={item.id}>
                <TooltipComponent position="TopCenter" content={item.desc}>
                    <div id={item.id}>
                        <span>{item.desc}</span>
                    </div>{' '}
                </TooltipComponent>
            </div>
        );
    }

    render() {
        let dynamicFilterMultiSelect = '';
        if (this.dynamicFilters && this.dynamicFilters.length > 0) {
            dynamicFilterMultiSelect = this.DynamicFiltersSelectButton();
        }
        const cssClass = this.props.cssClass;
        const filtersCssClass = this.props.onClearFilters ? 'filters-filter-controls' : 'filters-filter-control';
        return (
            <div id="filters-panel" className={cssClass}>
                {this.props.isFilterLoading ? (
                    <FiltersPanelSkeleton inputCount={this.props.filterSkeletonProps?.inputCount} />
                ) : (
                    <>
                        <div id="filters-container" key={this.panelId + this.state.count}>
                            {this.state.filterInputs}
                        </div>
                        <div className={filtersCssClass}>
                            {dynamicFilterMultiSelect}

                            {(cssClass === 'filters-vertical-flex' || cssClass === 'filters-horizontal-flex') && (
                                <React.Fragment>
                                    {this.props.onClearFilters && (
                                        <button
                                            type="button"
                                            className="btn btn-secondary e-btn clear-filters"
                                            onClick={this.clearFilters}
                                        >
                                            {this.props.enableSoftReset ? 'Reset' : 'Clear'}
                                        </button>
                                    )}
                                    <ProgressButton
                                        type="button"
                                        className="filters-panel-search"
                                        buttonRef={(filtersButton) => (this.filtersButton = filtersButton)}
                                        disabled={this.props.searchDisabled}
                                        handleClick={this.onClickSearch}
                                    >
                                        Search
                                    </ProgressButton>
                                </React.Fragment>
                            )}
                        </div>
                        {this.state.errors?.length > 0 &&
                            this.state.errors?.map((error) => <p className="filter-panel-error-message"> {error} </p>)}
                    </>
                )}
            </div>
        );
    }
}

// Props:
// enableSoftReset: Will revert filters to default filter values
FiltersPanel.defaultProps = {
    staticFilters: [],
    dynamicFilters: [],
    searchDisabled: false,
    enableSoftReset: false,
    cssClass: 'filters-vertical-flex',
};

FiltersPanel.propTypes = {
    staticFilters: PropTypes.array,
    dynamicFilters: PropTypes.array,
    initialValues: PropTypes.object,
    onClearFilters: PropTypes.func,
    onClickSearch: PropTypes.func.isRequired,
    searchDisabled: PropTypes.bool,
    enableSoftReset: PropTypes.bool,
    cssClass: PropTypes.string,
};

export default FiltersPanel;
